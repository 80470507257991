import React from 'react'
import _ from 'lodash'

import RaccAlertMessage from 'Shared/racc_alert_message'

type noticeMessagesProps = {
  messages: string | string[]
}

const NoticeMessages = ({ messages }: noticeMessagesProps): JSX.Element => {
  const renderMessages =
    typeof messages === 'object' ? (
      _.map(
        messages,
        (msg: string): JSX.Element => <div key={_.uniqueId()}>{msg}</div>
      )
    ) : (
      <div>{messages}</div>
    )

  return <RaccAlertMessage type='notice'>{renderMessages}</RaccAlertMessage>
}

export default NoticeMessages
